import { ref, watch, computed } from '@vue/composition-api'
import { title } from '@core/utils/filter'
import Vue from 'vue'
// Notification

import axios from '@axios'
import store from '@/store'

export default function useServicesList() {
  const reftransferListTable = ref(null)
  const mainServiceList = ref('')
  const mainServiceOption = ref([])
  // Table Handlers
  const tableColumns = [
    { key: 'id', label: ' الرقم', formatter: title },
    { key: 'from_main_service.name', label: 'من', formatter: title },
    { key: 'to_main_service.name', label: 'إلى', formatter: title },
    { key: 'person.first_name', label: 'الفرد', formatter: title },

    {
      isActive: false,
      key: 'notes',
      label: 'الملاحظات',
      formatter: title,

    },
    { key: 'approving_stage', label: ' approving Stage', formatter: title },
    { key: 'type', label: 'نوع التحويل', formatter: title },
    // { key: "user_id", label: "الموظف",formatter: title,},
    { key: 'created_at', label: 'التاريخ', formatter: title },
    { key: 'notes', label: 'notes' },
    { key: 'created_by', label: 'الموظف' },
    { key: 'proposal_services.name', label: 'الخدمة الفرعية' },

    { key: 'actions', label: 'الخيارات' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const TransferType = ref('outgoing')
  const MainserviceTransfer = ref()
  const dataMeta = computed(() => {
    const localItemsCount = reftransferListTable.value
      ? reftransferListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    reftransferListTable.value.refresh()
  }
  watch(
    [
      TransferType,
      MainserviceTransfer,
      currentPage,
      perPage,
      searchQuery,

    ],
    () => {
      refetchData()
    },
  )

  const fetchListServices = (ctx, callback) => {
    const userData = JSON.parse(localStorage.getItem('userData'))
//   console.log("userData",userData)
    // if(userData.roles[0].id==1){
    //     store
    //     .dispatch("app-services/fetchListServicesInforamition")
    //     .then((response) => {
    //       const { data, total } = response.data;
    //       //console.log(response.data)
    //       callback(data);
    //       totalUsers.value = total;
    //     })
    //     .catch(() => {
    //       Vue.swal({
    //             title: "حدثت مشكلة في استرجاع البيانات",
    //     icon: "error",

    //       confirmButtonText: "موافق",
    //       customClass: {
    //         confirmButton: "btn btn-primary",
    //       },
    //       buttonsStyling: false,
    //     });
    //     });

    // }else{z
    store
      .dispatch('app-services/fetchListServices', {
        referrals_source: TransferType.value,
        page: currentPage.value,
        page_size: perPage.value,
        main_service_id: MainserviceTransfer.value,

        referral_status: 'new_referrals',

      })
      .then(response => {
        const { data, total } = response.data
        // console.log(response.data)
        callback(data)
        totalUsers.value = total
      })
      .catch(error => {
        Vue.swal({
          title: `${error.response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    // }
  }

  return {
    fetchListServices,
    mainServiceOption,
    mainServiceList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,

    reftransferListTable,
    TransferType,
    MainserviceTransfer,

    refetchData,

  }
}
