<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <b-row v-if="role.roles.some( el => el['name'] === 'information_manager' )" class="p-2">
        <b-col
          md="6"
          xl="3"
        >
          <validation-provider
            #default="{ errors }"
            name="service"
            rules="required"
          >
            <b-form-group
              label-for="service"
              :state="errors.length > 0 ? false : null"
            >
        
              <label> الخدمة</label>
              <v-select
                v-model="MainserviceTransfer"
                :reduce="(val) => val.value"
                :options="optionService"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- <b-col
          md="6"
          xl="3"
        >
          <validation-provider
            #default="{ errors }"
            name="documentType"
            rules="required"
          >
            <b-form-group
              label-for="cardNumber"
              :state="errors.length > 0 ? false : null"
            >
              <label> الخدمة الفرعية </label>
              <v-select
                v-model="main_service_id"
                :reduce="(val) => val.value"
                :options="optionSubServices"
              />
            </b-form-group>
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col> -->
      </b-row>

      <b-table
        ref="reftransferListTable"
        class="position-relative"
        :items="fetchListServices"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
       
      <template #cell(type)="data">
          <span v-if="data.item.type=='assign'"> تحويل</span>
          <span v-else> تحويل اخر</span>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";

import useAcceptedTransfer from "./useAcceptedTransfer";
import { max } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import servicesTransferStoreModule from "./servicesTransferStoreModule";
import { jsontoexcel } from "vue-table-to-excel";
export default {
  data() {
    return {
      notes: '',
      optionService: [],
      proposal_service_id: '',
     
      // Mainservice: '',
      optionSubServices: [],
    }
  },
  watch: {
    MainserviceTransfer() {
      this.fetchListServices()
    },
  
  },

  created() {
    this.getServiceType()
  },
  methods: {
    getServiceType() {
      this.$http.get('/api/v1/beneficiaries_page/get_dropdown_data').then(res => {
        const proposal_services = res.data.data.main_services
        proposal_services.forEach(el => {
          this.optionService.push({ label: el.name, value: el.id })
        })
      })
    }},
  setup() {
    const Services_APP_STORE_MODULE_NAME = "app-services-transfer";
    const role = JSON.parse(localStorage.getItem("userData"));
    //console.log(role)
    // Register module
    if (!store.hasModule(Services_APP_STORE_MODULE_NAME))
      store.registerModule(Services_APP_STORE_MODULE_NAME, servicesTransferStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Services_APP_STORE_MODULE_NAME))
        store.unregisterModule(Services_APP_STORE_MODULE_NAME);
    });
   


    const {
      fetchListServices,
      tableColumns,

      perPage,
      currentPage,
      totalUsers,
      mainService,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
      MainserviceTransfer,

    } = useAcceptedTransfer();

    return {
      MainserviceTransfer,
      fetchListServices,
      tableColumns,
      role,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      reftransferListTable,
      refetchData,
    };
  },
  components: {
   
    flatPickr,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },

};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
