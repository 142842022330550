import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import Vue from "vue";
// Notification

import axios from "@axios";

export default function useRejectedTransfer() {



  const TransferType=ref('outgoing')

  const reftransferListTable=ref(null)
  const mainServiceList=ref('')
  const mainServiceOption=ref([])
  // Table Handlers
  const tableColumns = [
    { key: "id", label: " الرقم",formatter: title,},
    { key: "from_main_service.name", label: "من",formatter: title,},
    { key: "to_main_service.name", label: "إلى",formatter: title,},
    { key: "person.first_name", label: "الفرد",formatter: title,},
    { key: "user_id", label: "الموظف",formatter: title,},
    { key: "created_at", label: "التاريخ",formatter: title,},
    { key: "type", label: "نوع التحويل",formatter: title,},
    {
      key: "approving_stage",
      label: "تحويل الى",
      formatter: title,

    },
    {
      key: "notes",
      label: "الملاحظات",
      formatter: title,

    },
   

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);


  const dataMeta = computed(() => {
    const localItemsCount = reftransferListTable.value
      ? reftransferListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });
  const MainserviceTransfer = ref()
  const refetchData = () => {
    reftransferListTable.value.refresh();
  };
  watch(
    [
      TransferType,
      currentPage,
      perPage,
      MainserviceTransfer,
      searchQuery,

    ],
    () => {
      refetchData();
    }
  );


  const fetchListServices = (ctx, callback) => {
    let userData= JSON.parse(localStorage.getItem("userData"));
    //console.log("userDatafetchListServices",userData.roles[0].id)
  
    store
      .dispatch("app-services/fetchListServices",{
     
        page: currentPage.value,
        page_size:perPage.value,
        referrals_source:TransferType.value,
        referral_status:'rejected',
        main_service_id: MainserviceTransfer.value,
      })
      .then((response) => {
        const { data, total } = response.data;
        //console.log(response.data)
        callback(data);
        totalUsers.value = total;
      })
      .catch(error => {
        Vue.swal({
          title: `${error.response.data.message}`,
          icon: 'error',

          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    
  };





  return {
    TransferType,
    fetchListServices,
    mainServiceOption,
    mainServiceList,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    MainserviceTransfer,
    reftransferListTable,

    refetchData,

  };
}
